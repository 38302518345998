import React from "react";
import { Button, Container, Image, Nav, Navbar } from "react-bootstrap";
import logo from "./img/logo192.svg" ;
import telegramfirst from "./img/telegramfirst.svg"

export  class Header extends React.Component{
    render(){
        return(
            
     
    <Navbar expand="lg" className="bg-body-tertiary headheader">
      <Container fluid>
        <Navbar.Brand href="/"><Image className="logo" src={logo}/></Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px'}}
            navbarScroll
          >
            <Nav.Link className="custom-link" href="#action1">О компании</Nav.Link>
            <Nav.Link className="custom-link" href="#action2">Каталог</Nav.Link>
            <Nav.Link className="custom-link" href="#action3">Процесс покупки</Nav.Link>
            <Nav.Link className="custom-link" href="#action4">Доставка</Nav.Link>
            <Nav.Link className="custom-link" href="#action5">Блог</Nav.Link>
            <Nav.Link className="custom-link" href="#action6">Отзывы</Nav.Link>
          </Nav>
        </Navbar.Collapse>
         <Button><Image src={telegramfirst}/></Button>
      </Container>
    </Navbar>
                
            
        )
    }
} 