import React  from 'react';

import { Carousel, Container, Image} from "react-bootstrap";

export class Carus extends React.Component {
  render() {
    const { slides } = this.props; // получаем массив слайдов из пропсов
    return (
      
      <Carousel className='caruscar' controls={false} interval={3000}>
        {slides.map((slide, index) => (
          <Carousel.Item key={index}>
            <Image src={slide.image} className='img-fluid' />
          </Carousel.Item>
        ))}
      </Carousel>
     
    );
  }
}