import React from 'react';
import { Container, Row, Col, Image } from "react-bootstrap";

export class Preim extends React.Component {

   
    componentDidMount() {
        const bakElement = document.querySelector('.bak');
        const container = document.querySelector('.preimblock');

        const updatePosition = () => {
            const containerRect = container.getBoundingClientRect();
            const bakHeight = bakElement.offsetHeight;
            const windowHeight = window.innerHeight;

            
            if (containerRect.top > windowHeight / 2) {
                
                bakElement.style.position = 'absolute';
                bakElement.style.top = '10%';
                bakElement.style.left = '50%';
                bakElement.style.transform = 'translate(-50%, -50%)';
            } 
           
            else if (  windowHeight / 2 <= containerRect.bottom) {
                
                bakElement.style.position = 'fixed';
                bakElement.style.top = '50%';
                bakElement.style.left = '50%';
                bakElement.style.transform = 'translate(-50%, -50%)';
            } 
           
            else {
                
                bakElement.style.position = 'absolute';
                bakElement.style.top = `${containerRect.height - bakHeight}px`;
                bakElement.style.left = '50%';
                bakElement.style.transform = 'translate(-50%, 0)';
            }
            
        };

        window.addEventListener('scroll', updatePosition);
        window.addEventListener('resize', updatePosition);
        updatePosition(); 
    }

    componentWillUnmount() {
        
        window.removeEventListener('scroll', this.updatePosition);
        window.removeEventListener('resize', this.updatePosition);
    }

    render() {
        return (
            <Container fluid>
            <Container fluid style={{ position: 'relative' }} className='preimblock d-none d-lg-block'>
                <Row className='bak'>
                    <Col style={{ textAlign: 'center', fontSize: '3vw' }} lg={{ span: 4, offset: 4 }}>
                        Доверьте нам выбор своего авто - мы знаем толк в автомобилях!
                    </Col>
        </Row>
         <Row>
            <Col lg={2}><Image  src={require('./img/preim/preim1.png')} className='img-fluid' /></Col>
            <Col lg={4} style={{ position: 'relative '}}><div className='divpreim' style={{position: 'absolute', bottom: '0'}}>
                <Row className='zaglpreim'>Техническое состояние</Row>
                <Row className='textpreim'>Благодаря качественные дорогам и хорошим 
            климатическим условиям в США машины в прекрасном техническом состоянии.
            </Row></div></Col>
         </Row>
         <Row><Col lg={{ span: 2, offset: 7 }}><Image  src={require('./img/preim/preim2.png')} className='img-fluid' style={{position: 'relative',
    bottom: '2vw'}} /></Col></Row>
         <Row>
            <Col lg={{ span: 4, offset: 1 }}>
            <div className='divpreim'>
              <Row className='zaglpreim'>Современная комплетация</Row>
              <Row className='textpreim'>Машины оснащены последними новинками: климат-контроль, системы безопасности, 
                гидроусилитель руля, мультимедийные системы и т.д.
              </Row>
             </div>
            </Col>
            <Col lg={{ span: 4, offset: 2 }}>
             <div className='divpreim'>
              <Row className='zaglpreim'>Выгодные цены</Row>
              <Row className='textpreim'>Конкурентные цены. Цены ниже чем на Европейском автомобильном рынке.
                Также цены дешевле, чем в России, даже с учетом дотавки и растоможки.
              </Row>
             </div>
            </Col>
         </Row>
        <Row style={{ marginTop: '3vw'}}>
            <Col lg={{ span: 2, offset: 2 }}><Image  src={require('./img/preim/preim3.png')} className='img-fluid'/></Col>
            <Col lg={{ span: 2, offset: 1 }} style={{ position: 'relative'}}><Image  src={require('./img/preim/preim4.png')} className='img-fluid' style={{ position: 'absolute'}}/></Col>
        </Row>
        <Row style={{ marginTop: '7vw'}}> 
            <Col lg={{ span: 4, offset: 1 }}>
            <div className='divpreim'>
              <Row className='zaglpreim'>Небольшой пробег</Row>
              <Row className='textpreim'>Страховые компании в Америке выставляют машины на аукцион при небольших деффектах,
                 поэтому большая вероятность приобрести автомобиль с небольшим пробегом.
              </Row>
             </div>
            </Col>
            <Col lg={{ span: 4, offset: 3 }}>
             <div className='divpreim'>
              <Row className='zaglpreim'>Большой ассортимент</Row>
              <Row className='textpreim'>Американский автомобильный рынок представляет собой многообразие машин различных марок, 
                а также наличие последних актуальных моделей.
              </Row>
             </div>
            </Col>
         </Row>
         <Row style={{ marginTop: '3vw'}}>
            <Col lg={{ span: 2, offset: 2 }}><Image  src={require('./img/preim/preim5.png')} className='img-fluid'/></Col>
            <Col lg={{ span: 2, offset: 1 }} ><Image  src={require('./img/preim/preim6.png')} className='img-fluid'/></Col>
        </Row>
        </Container>
        <Container fluid style={{padding:'0'}} className="d-block d-lg-none">
        <Row>
            <Col xs={12} style={{padding:'0', marginBottom: '3vw', marginTop: '2.5vw'}}><div className='divpreim'>
                <Row className='zaglpreim'>Техническое состояние</Row>
                <Row className='textpreim'>Благодаря качественные дорогам и хорошим 
            климатическим условиям в США машины в прекрасном техническом состоянии.
            </Row></div></Col>
         </Row>
         <Row>
            <Col style={{padding:'0', marginBottom: '3vw'}}><Image src={require('./img/preim/mobilepreim1.png')} className='img-fluid'/></Col>
         </Row>
         <Row>
            <Col xs={12} style={{padding:'0', marginBottom: '3vw'}}><div className='divpreim'>
                <Row className='zaglpreim'>Современная комплетация</Row>
                <Row className='textpreim'>Машины оснащены последними новинками: климат-контроль, системы безопасности, 
                гидроусилитель руля, мультимедийные системы и т.д.
            </Row></div></Col>
         </Row>
         <Row>
            <Col style={{padding:'0', marginBottom: '3vw'}}><Image src={require('./img/preim/mobilepreim2.png')} className='img-fluid'/></Col>
         </Row>
         <Row>
            <Col xs={12} style={{padding:'0', marginBottom: '3vw'}}><div className='divpreim'>
                <Row className='zaglpreim'>Выгодные цены</Row>
                <Row className='textpreim'>Конкурентные цены. Цены ниже чем на Европейском автомобильном рынке.
                Также цены дешевле, чем в России, даже с учетом дотавки и растоможки.
            </Row></div></Col>
         </Row>
         <Row>
            <Col style={{padding:'0', marginBottom: '3vw'}}><Image src={require('./img/preim/mobilepreim3.png')} className='img-fluid'/></Col>
         </Row>
         <Row>
            <Col xs={12} style={{padding:'0', marginBottom: '3vw'}}><div className='divpreim'>
                <Row className='zaglpreim'>Небольшой пробег</Row>
                <Row className='textpreim'>Страховые компании в Америке выставляют машины на аукцион при небольших деффектах,
                поэтому большая вероятность приобрести автомобиль с небольшим пробегом.
            </Row></div></Col>
         </Row>
         <Row>
            <Col style={{padding:'0', marginBottom: '3vw'}}><Image src={require('./img/preim/mobilepreim4.png')} className='img-fluid'/></Col>
         </Row>
         <Row>
            <Col xs={12} style={{padding:'0', marginBottom: '3vw'}}><div className='divpreim'>
                <Row className='zaglpreim'>Большой ассортимент</Row>
                <Row className='textpreim'>Американский автомобильный рынок представляет собой многообразие машин различных марок, 
                а также наличие последних актуальных моделей.
            </Row></div></Col>
         </Row>
        </Container>
        </Container>

        )
    }
}