import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import  CarList from "../filter";

export  class Catalog extends React.Component{
    render(){
        return(
           <Container fluid>
            <Row className='razdelzagl1'><Col>Выбери идеальное авто для себя</Col></Row>
            <CarList/>
           </Container>
        )
    }
}