import React from 'react';
import { Container, Row, Col, Image, Button } from "react-bootstrap";


export  class Podpiska extends React.Component{
    render(){
        return(
            <Container fluid style={{ backgroundColor: 'white', borderRadius:'25px'}}>
                <Container fluid className='d-none d-lg-block'>
                <Row>
                    <Col style={{color: 'black', paddingLeft:'30px', paddingTop:'10px'}} >
                     <Row style={{fontSize:'3vw', fontWeight:'600', textAlign:'left'}}>Подписывайтесь на наш канал в социальных сетях</Row>
                     <Row style={{marginTop:'3vw',}}>
                        <Col lg={5} className='podpiska' style={{display: 'flex', justifyContent: 'left', alignItems: 'center' }}><span className='dot' ></span><span style={{width:'4px'}}></span>Каталог автомобилей</Col>
                        <Col lg={7} className='podpiska' style={{display: 'flex', justifyContent: 'left', alignItems: 'center' }}><span className='dot' ></span><span style={{width:'4px'}}></span>Новинки и последние поступления</Col>
                     </Row>
                     <Row style={{marginTop:'2.5vw'}} >
                        <Col lg={4}><Button className='hover-outline-btn btnn'><span>Telegram</span><Image src={require('./img/telega.png')}/></Button></Col>
                        <Col lg={4}><Button className='hover-outline-btn btnn'><span>Whatsapp</span><Image src={require('./img/whatsapp.png')}/></Button></Col>
                     </Row>
                    </Col>
                    <Col lg={5} style={{padding:'1.5vw', paddingRight: '15px'}}>
                     <Image src={require('./img/preim/jeeppod.png')} className='img-fluid'/>
                    </Col>
                </Row>
                </Container>
                <Container fluid className='d-block d-lg-none' >
                <Row>
                    <Col style={{color: 'black', paddingTop:'10px'}} >
                     <Row style={{fontSize:'18px', fontWeight:'600', textAlign:'left'}}>Подписывайтесь на наш канал в социальных сетях</Row>
                     <Row style={{marginTop:'3vw',}}>
                        <Col lg={5} className='podpiska' style={{display: 'flex', justifyContent: 'left', alignItems: 'center', paddingLeft:'0', fontSize:'16px' }}><span className='dot' ></span><span style={{width:'4px'}}></span>Каталог автомобилей</Col>
                        <Col lg={7} className='podpiska' style={{display: 'flex', justifyContent: 'left', alignItems: 'center', paddingLeft:'0', fontSize:'16px' }}><span className='dot' ></span><span style={{width:'4px'}}></span>Новинки и поступления</Col>
                     </Row>
                     <Row style={{marginTop:'2.5vw'}} >
                        <Col lg={4}><Button className='hover-outline-btn btnn'><span>Telegram</span><Image src={require('./img/telega.png')} className='img-fluid'/></Button></Col>
                        <Col lg={4} style={{marginTop:'2vw', marginBottom:'4vw'}}><Button className='hover-outline-btn btnn'><span>Whatsapp</span><Image src={require('./img/whatsapp.png')} className='img-fluid'/></Button></Col>
                     </Row>
                    </Col>
                    <Col lg={12} style={{padding:'3vw'}}>
                     <Image src={require('./img/preim/jeeppod.png')} className='img-fluid'/>
                    </Col>
                </Row>
                </Container>
            </Container>
    

        )
    }
}