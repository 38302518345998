import './App.css';
import {BrowserRouter, Routes, Route } from 'react-router-dom';
import { Home } from './pages/home';
import { Catalog } from './pages/catalog';
import { Header } from './header';
import { Footer } from './footer';


function App() {
  return (
    <div className="App">
      <Header/>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Catalog/>}/>
          <Route path="*" element={<Home/>}/>
        </Routes>
      </BrowserRouter>
      <Footer/>
    </div>
  );
}

export default App;
