import React from 'react';
import { Container, Row, Col, Accordion  } from "react-bootstrap";




export  class Rabota extends React.Component{
    render(){
        return(
            <Container fluid style={{padding:'0'}}>
                <Container fluid className='d-none d-lg-block' style={{padding:'0'}}>
                <Row>
        {/* Левый элемент с позиционированием sticky */}
        <Col lg={4} md={4}>
          <div style={{ 
            position: 'sticky', 
            top: '0', 
            padding: '20px', 
            height: '20vh', 
            overflowY: 'auto', 
            paddingLeft: '0',
            textAlign: 'left',
          }}>
            <Row>
                <Col style={{ fontSize:'25px', fontWeight:'600'}}>Этапы заключения сделка от заявки до доставки автомобиля
                в Ваш город</Col>
            </Row>
          </div>
        </Col>

        {/* Правый элемент, который прокручивается */}
        <Col lg={8} md={8}>
          <div style={{ padding: '20px' }}>
            <Row className='etapyrow'>
                <Col lg={1} className='etapynumb'>01</Col>
                <Col className='etapycol'><div className='etapyzagl'>Заявка</div>
                <div className='etapytext'>Заявка на сайте, звонок или пишите нам в Telegram</div></Col>
            </Row>
            <Row className='etapyrow1'>
                <Col lg={1} className='etapynumb'>02</Col>
                <Col className='etapycol'><div className='etapyzagl'>Подбор автомобиля</div>
                <div className='etapytext'>На сайте или в Telegram канале. Если не определись с автомобилем, то пишите, наш менеджер поможет с выбором</div></Col>
            </Row>
            <Row className='etapyrow'>
                <Col lg={1} className='etapynumb'>03</Col>
                <Col className='etapycol'><div className='etapyzagl'>Коммерческое предложение</div>
                <div className='etapytext'>Составляем коммерческое предложение, в котором будут отражены все характеристки желаемого автомобиля и диапазон цены</div></Col>
            </Row>
            <Row className='etapyrow1'>
                <Col lg={1} className='etapynumb'>04</Col>
                <Col className='etapycol'><div className='etapyzagl'>Заключение договора</div>
                <div className='etapytext'>Составляем договор, учитывая ваши пожелания</div></Col>
            </Row>
            <Row className='etapyrow'>
                <Col lg={1} className='etapynumb'>05</Col>
                <Col className='etapycol'><div className='etapyzagl'>Приобретение автомобиля на аукционе</div>
                <div className='etapytext'>Наши специалисты подберут автомобиль под Ваш запрос. Автомобилю присваивается VIN-номер</div></Col>
            </Row>
            <Row className='etapyrow1'>
                <Col lg={1} className='etapynumb'>06</Col>
                <Col className='etapycol'><div className='etapyzagl'>Составление приложения к договору</div>
                <div className='etapytext'>VIN-номер отображается в приложении к договору и фиксируется окончательная стоимость автомобиля с доставкой до Вашего города</div></Col>
            </Row>
            <Row className='etapyrow'>
                <Col lg={1} className='etapynumb'>07</Col>
                <Col className='etapycol'><div className='etapyzagl'>Доставка автомобиля</div>
                <div className='etapytext'>Доставка автомобиля в порт, оформление авто на таможенном терминале</div></Col>
            </Row>
            <Row className='etapyrow1'>
                <Col lg={1} className='etapynumb'>08</Col>
                <Col className='etapycol'><div className='etapyzagl'>Оформление ЭПТС</div>
                <div className='etapytext'>Получаем СБКТС и ЭПТС РФ на автомобиль ввезенный из Америки</div></Col>
            </Row>
            <Row className='etapyrow'>
                <Col lg={1} className='etapynumb'>09</Col>
                <Col className='etapycol'><div className='etapyzagl'>Получение автомобиля</div>
                <div className='etapytext'>Акт приема-передачи</div></Col>
            </Row>
          </div>
        </Col>
      </Row>
      </Container>
      <Container fluid style={{padding:'0', marginTop:'3vw'}} className='d-block d-lg-none'>
      <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0" className='accordion-item'>
        <Accordion.Header><div style={{ paddingRight:'1.5vw'}}>01</div><div>Заявка</div></Accordion.Header>
        <Accordion.Body>
           Заявка на сайте, звонок или пишите нам в Telegram
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header><div style={{ paddingRight:'1.5vw'}}>02</div><div>Подбор автомобиля</div></Accordion.Header>
        <Accordion.Body>
         На сайте или в Telegram канале. Если не определись с автомобилем, то пишите, наш менеджер поможет с выбором
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header><div style={{ paddingRight:'1.5vw'}}>03</div><div>Коммерческое предложение</div></Accordion.Header>
        <Accordion.Body>
        Составляем коммерческое предложение, в котором будут отражены все характеристки желаемого автомобиля и диапазон цены
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header><div style={{ paddingRight:'1.5vw'}}>04</div><div>Заключение договора</div></Accordion.Header>
        <Accordion.Body>
        Составляем договор, учитывая ваши пожелания
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header><div style={{ paddingRight:'1.5vw'}}>05</div><div>Приобретение автомобиля</div></Accordion.Header>
        <Accordion.Body>
        Наши специалисты подберут автомобиль под Ваш запрос. Автомобилю присваивается VIN-номер
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header><div style={{ paddingRight:'1.5vw'}}>06</div><div>Составление приложений</div></Accordion.Header>
        <Accordion.Body>
        VIN-номер отображается в приложении к договору и фиксируется окончательная стоимость автомобиля с доставкой до Вашего города
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
        <Accordion.Header><div style={{ paddingRight:'1.5vw'}}>07</div><div>Доставка автомобиля</div></Accordion.Header>
        <Accordion.Body>
        Доставка автомобиля в порт, оформление авто на таможенном терминале
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="7">
        <Accordion.Header><div style={{ paddingRight:'1.5vw'}}>08</div><div>Оформление ЭПТС</div></Accordion.Header>
        <Accordion.Body>
        Получаем СБКТС и ЭПТС РФ на автомобиль ввезенный из Америки
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="8">
        <Accordion.Header><div style={{ paddingRight:'1.5vw'}}>09</div><div>Получение автомобиля</div></Accordion.Header>
        <Accordion.Body>
        Акт приема-передачи
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
      </Container>
    </Container>
     
        )
    }
}