import React  from 'react';
import {  Container, Button, Card, Row, Col } from "react-bootstrap";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Carus } from './carusel';

const MultiSlideCarouselCar = () => {
    // Настройки для карусели
    const responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 1024 },
        items: 3, // Показывать 3 элементов одновременно
      },
      desktop: {
        breakpoint: { max: 1024, min: 768 },
        items: 2,
      },
      tablet: {
        breakpoint: { max: 768, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };

    const slidesData = [
        {
          image: require('./img/xDrive40i1.png'), // путь к изображению
        },
        {
          image: require('./img/xDrive40i2.png'),
        
        },
        {
          image: require('./img/xDrive40i3.png'),
         
        }
      ];
      const slidesData1 = [
        {
          image: require('./img/Land Rover Range Rover Westminster.png'), // путь к изображению
        },
        {
          image: require('./img/Land Rover Range Rover Westminster2.png'),
        
        },
        {
          image: require('./img/Land Rover Range Rover Westminster3.png'),
         
        }
      ];
      const slidesData2 = [
        {
          image: require('./img/Mercedes-Benz GLS450.png'), // путь к изображению
        },
        {
          image: require('./img/Mercedes-Benz GLS4502.png'),
        
        },
        {
          image: require('./img/Mercedes-Benz GLS4503.png'),
         
        }
      ];
      const slidesData3 = [
        {
          image: require('./img/RAM1.png'), // путь к изображению
        },
        {
          image: require('./img/RAM2.png'),
        
        },
        {
          image: require('./img/RAM3.png'),
         
        }
      ];
      const slidesData4 = [
        {
          image: require('./img/audiq8.png'), // путь к изображению
        },
        {
          image: require('./img/audiq81.png'),
        
        },
        {
          image: require('./img/audiq83.png'),
         
        }
      ];
  
    return (
      <div className="carousel-wrapper">
        
        <Carousel
          responsive={responsive}
          arrows={true} // Убираем стрелки
          showDots={false} // Убираем точки навигации
          infinite={true} // Бесконечное воспроизведение
          autoPlay={false} // Включаем автоматическое воспроизведение
          autoPlaySpeed={0} // Устанавливаем минимальную паузу
          keyBoardControl={true} // Отключаем управление с клавиатуры
          transitionDuration={2} // Минимальная продолжительность смены слайда
          className="continuous-carousel-car" // Добавляем CSS-класс для кастомной анимации
          containerClass="carousel-container"
          itemClass="carousel-item-padding-car" // Добавляем отступы между слайдами
          customTransition="all 1s ease" // Добавляем отступы между слайдами
        >
          {/* 7 слайдов с изображениями */}
          <div className='divcar'>
           <Card style={{ width: '100%' }}>
           <Carus slides={slidesData} />
             <Card.Body className='carcard'>
              <Card.Title className='NameCar'>BMW X5 XDrive40i</Card.Title>
              <Card.Text>
                <Row className='rowgod'><Col className='god'>Год</Col><Col className='godname'>2021 г.</Col></Row>
                <Row className='rowprobeg'><Col className='probeg'>Пробег</Col><Col className='probegname'>96 000</Col></Row>
                <Row className='rowcena'><Col className='cena'>5 600 000 руб.</Col></Row>
              </Card.Text>
                <Button className='hover-outline-btn btnn' >Забронировать авто</Button>
            </Card.Body>
           </Card>
          </div>
          <div>
          <Card style={{ width: '100%' }}>
           <Carus slides={slidesData1} />
             <Card.Body className='carcard'>
              <Card.Title className='NameCar'>Land Rover Range Rover Westminster</Card.Title>
              <Card.Text>
                <Row className='rowgod'><Col className='god'>Год</Col><Col className='godname'>2021 г.</Col></Row>
                <Row className='rowprobeg'><Col className='probeg'>Пробег</Col><Col className='probegname'>57 000</Col></Row>
                <Row className='rowcena'><Col className='cena'>5 900 000 руб.</Col></Row>
              </Card.Text>
                <Button className='hover-outline-btn btnn'>Забронировать авто</Button>
            </Card.Body>
           </Card></div>
          <div><Card style={{ width: '100%' }}>
           <Carus slides={slidesData2} />
             <Card.Body className='carcard'>
              <Card.Title className='NameCar'>Mercedes-Benz GLS450</Card.Title>
              <Card.Text>
                <Row className='rowgod'><Col className='god'>Год</Col><Col className='godname'>2021 г.</Col></Row>
                <Row className='rowprobeg'><Col className='probeg'>Пробег</Col><Col className='probegname'>60 000</Col></Row>
                <Row className='rowcena'><Col className='cena'>6 100 000 руб.</Col></Row>
              </Card.Text>
                <Button className='hover-outline-btn btnn'>Забронировать авто</Button>
            </Card.Body>
           </Card></div>
           <div><Card style={{ width: '100%' }}>
           <Carus slides={slidesData3} />
             <Card.Body className='carcard'>
              <Card.Title className='NameCar'>RAM 1500 Rebel </Card.Title>
              <Card.Text>
                <Row className='rowgod'><Col className='god'>Год</Col><Col className='godname'>2022 г.</Col></Row>
                <Row className='rowprobeg'><Col className='probeg'>Пробег</Col><Col className='probegname'>60 000</Col></Row>
                <Row className='rowcena'><Col className='cena'>7 150 000 руб.</Col></Row>
              </Card.Text>
                <Button className='hover-outline-btn btnn'>Забронировать авто</Button>
            </Card.Body>
           </Card></div>
           <div><Card style={{ width: '100%' }}>
           <Carus slides={slidesData4} />
             <Card.Body className='carcard'>
              <Card.Title className='NameCar'>Audi Q8 Premium+ </Card.Title>
              <Card.Text>
                <Row className='rowgod'><Col className='god'>Год</Col><Col className='godname'>2021 г.</Col></Row>
                <Row className='rowprobeg'><Col className='probeg'>Пробег</Col><Col className='probegname'>76 000</Col></Row>
                <Row className='rowcena'><Col className='cena'>5 300 000 руб.</Col></Row>
              </Card.Text>
                <Button className='hover-outline-btn btnn'>Забронировать авто</Button>
            </Card.Body>
           </Card></div>
        </Carousel>
      </div>
    );
}

export  class Popcar extends React.Component{
    render(){
        return(
        <Container fluid>
            <MultiSlideCarouselCar/>
            <Row className='buttoncat'>
            <Col><Button className='hover-outline-btn btnn'>Перейти в каталог</Button></Col>
            </Row>
        </Container>

        )
    }
}