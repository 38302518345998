import React  from 'react';
import { Row, Col, Image, Container, Button } from "react-bootstrap";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Popcar } from '../popcar';
import { Preim } from '../preim';
import { Podpiska } from '../podpiska';
import { Rabota } from '../etapyraboty';
import { Formblock } from '../formblock';


const MultiSlideCarousel = () => {
    // Настройки для карусели
    const responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 1024 },
        items: 5, // Показывать 5 элементов одновременно
      },
      desktop: {
        breakpoint: { max: 1024, min: 768 },
        items: 4,
      },
      tablet: {
        breakpoint: { max: 768, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
      },
    };

    
  
    return (
      <div className="carousel-wrapper">
        
        <Carousel
          responsive={responsive}
          arrows={false} // Убираем стрелки
          showDots={false} // Убираем точки навигации
          infinite={true} // Бесконечное воспроизведение
          autoPlay={true} // Включаем автоматическое воспроизведение
          autoPlaySpeed={0} // Устанавливаем минимальную паузу
          keyBoardControl={false} // Отключаем управление с клавиатуры
          transitionDuration={1} // Минимальная продолжительность смены слайда
          customTransition="none" // Отключаем стандартный переход
          className="continuous-carousel" // Добавляем CSS-класс для кастомной анимации
          containerClass="carousel-container"
          itemClass="carousel-item-padding" // Добавляем отступы между слайдами
        >
          {/* 7 слайдов с изображениями */}
          <div><Image src={require('../img/BMW.png')} className='img-fluid' /></div>
          <div><Image src={require('../img/Mercedes.png')} className='img-fluid' /></div>
          <div><Image src={require('../img/GMC.png')} className='img-fluid' /></div>
          <div><Image src={require('../img/Porsche.png')} className='img-fluid' /></div>
          <div><Image src={require('../img/RAM.png')} className='img-fluid' /></div>
          <div><Image src={require('../img/JEEP.png')} className='img-fluid' /></div>
          <div><Image src={require('../img/LandRover.png')} className='img-fluid' /></div>
        </Carousel>
      </div>
    );
  };
  const MultiSlideCarouselRight = () => {
    const responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 1024 },
        items: 5,
      },
      desktop: {
        breakpoint: { max: 1024, min: 768 },
        items: 4,
      },
      tablet: {
        breakpoint: { max: 768, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
      },
    };
  
    return (
      <div className="carousel-wrapper">
        <Carousel
          responsive={responsive}
          arrows={false}
          showDots={false}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={0}
          keyBoardControl={false}
          transitionDuration={1}
          customTransition="none"
          className="continuous-carousel-right"
          containerClass="carousel-container"
          itemClass="carousel-item-padding"
        >
          <div><Image src={require('../img/Volkswagen.png')} className='img-fluid' /></div>
          <div><Image src={require('../img/Subaru.png')} className='img-fluid' /></div>
          <div><Image src={require('../img/Lexus.png')} className='img-fluid' /></div>
          <div><Image src={require('../img/Genesis.png')} className='img-fluid' /></div>
          <div><Image src={require('../img/Chevrolet.png')} className='img-fluid' /></div>
          <div><Image src={require('../img/AstonMartin.png')} className='img-fluid' /></div>
          <div><Image src={require('../img/AlfaRomeo.png')} className='img-fluid' /></div>
        </Carousel>
      </div>
    );
  };
  
  
export  class Home extends React.Component{
    render(){
        return(
            <Container fluid>
            <Container fluid className='d-none d-lg-block'>
            <Row className='firstsqueare' >
              <Col className='mainblock' xs={6}>
              <Row>
                <Col className='textblock'>
                <Row>
                    <Col className='textalig nadpost' >
                    <span>Надежный поставщик с большим опытом</span>
                    </Col>
                </Row>
                <Row>
                    <Col className='textalig dost' >
                    <span>Доставка автомобилей</span>
                    </Col>
                </Row>
                <Row md="auto" className='USA'>
                    <Col className='textalign' ><span>из</span></Col><Col className='textal' ><Image className='flag' src={require('../img/flag.png')}/></Col><Col className='textalign textal'><span>Америки</span></Col>
                </Row>    
                
                </Col>
              </Row>
              <Row className='preim'>
                    <Col xs={4} className='preimcol border-line'>
                     <span class='textpreim' >Поможем с выбором</span>
                    </Col>
                    {/*<Col xl='auto' className='preimcol'><div class='vertical-line'></div></Col>*/}
                    <Col xs={4} className='preimcol border-line'>
                     <span class='textpreim'>Рассчитаем доставку до Вашего города</span>
                    </Col>
                    {/*<Col xl='auto' className='preimcol'><div class='vertical-line'></div></Col>*/}
                    <Col xs={4} className='preimcol'>
                     <span class='textpreim'>Быстрые сроки поставки</span>
                    </Col>
                </Row>
                <Row className='rowbutton'>
                    <Col><Button className='hover-outline-btn btnn'>Оставить заявку</Button></Col>
                    <Col><Button className='btnn'  variant='outline-light'>8 (913) 555-55-55</Button></Col>
                </Row>
              </Col>
              <Col xs={5} className='columnvertfirst'>
              <Row>
                <Col className='columnvert'>
                <Row>
                    <Col><Image src={require('../img/carone.png')} className='img-fluid'/></Col>
                </Row>
                <Row>
                    <Col><Image src={require('../img/cartwo.png')} className='img-fluid'/></Col>
                </Row>
                </Col>
                <Col><Image src={require('../img/carthree.png')} className='img-fluid'/></Col>
              </Row>
              </Col>
            </Row>
            </Container>
            
  
            <Container fluid className="d-block d-sm-none">
            <Row>
                <Col s={12}  className='mobilefirst'>  
                <Image src={require('../img/mobilecar.png')} className='img-fluid mobilecar'/>      
                <Row>
                    <Col  className='mobiletextalig mobilenadpost' >
                     <span>Надежный поставщик с большим опытом</span>
                    </Col>
                    <Row>
                     <Col className=' mobiletextalig  mobiledost' >
                      <span>Доставка автомобилей</span>
                     </Col>
                </Row>
                <Row xs="auto" className='mobileUSA'>
                    <Col className=' mobiletextalign' ><span>из</span></Col><Col xs="auto" className='mobiletextalign  mobiletextal'><span>Америки</span></Col>
                    <Col xs="auto" className='mobiletextal' ><Image className='mobileflag' src={require('../img/flag.png')}/></Col>
                </Row>
                <Row className='rowbutton'>
                    <Col><Button className='hover-outline-btn btnn'>Оставить заявку</Button></Col>
                    <Col><Button className='btnn'  variant='outline-light'>Позвонить</Button></Col>
                </Row>
                <Row className='mobilepreim'>
                    <Row>
                     <Col xs={12} className='mobilepreimcol'>
                      <span class='mobiletextpreim' >Поможем с выбором</span>
                     </Col>
                    </Row>
                    <Row>
                     <Col xs={12} className='mobilepreimcol'>
                      <span class='mobiletextpreim'>Рассчитаем доставку до Вашего города</span>
                     </Col>
                    </Row>
                    <Row>
                     <Col xs={12} className='mobilepreimcol'>
                      <span class='mobiletextpreim'>Быстрые сроки поставки</span>
                     </Col>
                    </Row>
                </Row>  
                 </Row>
                </Col>
            </Row>
            </Container>

            <Container fluid className="d-none d-sm-block d-lg-none">
            <Row>
                <Col s={12}  className='mobilefirst'>  
                <Image src={require('../img/Planshetcar.png')} className='img-fluid mobilecar'/>      
                <Row>
                    <Col  className='mobiletextalig mobilenadpost' >
                     <span>Надежный поставщик с большим опытом</span>
                    </Col>
                    <Row>
                     <Col className=' mobiletextalig  mobiledost' >
                      <span>Доставка автомобилей</span>
                     </Col>
                </Row>
                <Row xs="auto" className='mobileUSA'>
                    <Col className=' mobiletextalign' ><span>из</span></Col><Col xs="auto" className='mobiletextal' ><Image className='mobileflag' src={require('../img/flag.png')}/></Col><Col xs="auto" className='mobiletextalign  mobiletextal'><span>Америки</span></Col>
                </Row>
                <Row className='rowbutton'>
                    <Col><Button className='hover-outline-btn btnn'>Оставить заявку</Button></Col>
                    <Col><Button className='btnn'  variant='outline-light'>Позвонить</Button></Col>
                </Row>
                <Row className='mobilepreim'>
                    <Row>
                     <Col xs={12} className='mobilepreimcol'>
                      <span class='mobiletextpreim' >Поможем с выбором</span>
                     </Col>
                    </Row>
                    <Row>
                     <Col xs={12} className='mobilepreimcol'>
                      <span class='mobiletextpreim'>Рассчитаем доставку до Вашего города</span>
                     </Col>
                    </Row>
                    <Row>
                     <Col xs={12} className='mobilepreimcol'>
                      <span class='mobiletextpreim'>Быстрые сроки поставки</span>
                     </Col>
                    </Row>
                </Row>  
                 </Row>
                </Col>
            </Row>
            </Container>
            
            <Container fluid style={{padding: '0'}}>
              {/*Раздел прем брендов*/}
                <Row className='razdelzagl'><Col>Премиальные бренды</Col></Row>
                <MultiSlideCarousel />
                <MultiSlideCarouselRight/>
                {/*Раздел популярных тачек*/}
                <Row className='razdelzagl1'><Col>Популярные автомобили</Col></Row>
                 <Popcar/>
               {/*Раздел о компании*/}
             <Row className='razdelzagl1'><Col>О компании</Col></Row>
             <Row>
               <Col className='importNSK' lg={5}><span style={{ fontWeight:'300'}}>ImportNSK -</span> предоставляем полный комплекс услуг по доставке авто</Col>
               <Col lg={6} style={{padding: '26px', backgroundColor: '#ffff', color: 'black', textAlign: 'left', borderRadius: '2vw', marginLeft:'auto' }}>
                <Row className='aboutcomp'>Наша компания предлагает услуги по доставке автомобилей из США в Россию</Row>
                <Row className='aboutcompone'>Предоставляем широкий спектр услуг, включая подбор и покупку автомобиля, оформление необходимых документов, 
                перевозку машины на специализированных автовозах, страхование груза</Row>
                <Row className='aboutcomptwo'>Компания берет на себя все организационные моменты</Row>
                <Row><Col lg={6} style={{padding:'0'}}> <Button className='hover-outline-btn btnn'><span>Канал в Telegram</span><Image src={require('../img/telega.png')}/></Button></Col></Row>
               </Col>
             </Row>
             {/*Раздел прем брендов*/}
             <Row className='razdelzagl1'><Col>Преимущества автомобилей из Америки</Col></Row>
             <Preim/>
             {/*Раздел ссылок на вотс и телегу*/}
             <Row className='razdelzagl1'></Row>
              <Podpiska/>
              {/*Раздел о том как мы работаем*/}
              <Row className='razdelzagl1'><Col>Как мы работаем</Col></Row>
              <Rabota/>
              {/*Форма обратной связи*/}
              <Row className='razdelzagl1'><Col></Col></Row>
              <Formblock/>
            </Container>
            </Container>
        )
    }
}