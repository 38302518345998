import React from "react";
import { Button, Container, Form, Image, Nav, Navbar } from "react-bootstrap";
import logo from "./img/logo192.svg";


export  class Footer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            isFixed: false
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        if (window.scrollY > 200) {
            this.setState({ isFixed: true });
        } else {
            this.setState({ isFixed: false });
        }
    }

    render() {
        const navbarClass = this.state.isFixed ? 'fixeds' : 'displaynone';

        return (
            <Navbar expand="lg" className={`bg-body-tertiary ${navbarClass}`}>
                <Container fluid>
                    <Navbar.Brand href="/"><Image className="logo" src={logo}/></Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="me-auto my-2 my-lg-0"
                            style={{ maxHeight: '100px'}}
                            navbarScroll
                        >
                            <Nav.Link href="#action1">О компании</Nav.Link>
                            <Nav.Link href="#action2">Каталог</Nav.Link>
                            <Nav.Link href="#action2">Процесс покупки</Nav.Link>
                            <Nav.Link href="#action2">Доставка</Nav.Link>
                            <Nav.Link href="#action2">Блог</Nav.Link>
                            <Nav.Link href="#action2">Отзывы</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        )
    }
}