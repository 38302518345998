import React, { useState, useEffect } from 'react';
import { Card, Button, Row, Col, Form, Pagination } from 'react-bootstrap';

const carsData = [
  {
    name: 'BMW',
    year: 5,
    mileage: 96000,
    price: 5600000
  },
  {
    name: 'Subaru',
    year: 10,
    mileage: 96000,
    price: 4000000
  },
  {
    name: 'Mercedes',
    year: 20,
    mileage: 96000,
    price: 7000000
  }
];

const CarList = () => {
  const [selectedBrand, setSelectedBrand] = useState('Все марки');
  const [priceFrom, setPriceFrom] = useState('');
  const [priceTo, setPriceTo] = useState('');
  const [yearFrom, setYearFrom] = useState('');
  const [yearTo, setYearTo] = useState('');
  const [filteredCars, setFilteredCars] = useState(carsData);
  const [nb, setNb] = useState(null); // Переменная nb для хранения данных с сервера

  const [currentPage, setCurrentPage] = useState(1);
  const carsPerPage = 9;

  useEffect(() => {
    fetch('./API/index.php/cars/get')
      .then((response) => response.json())
      .then((data) => {
        setNb(data); // Сохраняем данные в переменной nb
        console.log('Данные с сервера:', data); // Выводим данные в консоль
      })
      .catch((error) => {
        console.error('Ошибка при получении данных с сервера:', error);
      });
  }, []);

  const handleApply = () => {
    const priceMin = priceFrom ? parseInt(priceFrom) : 0;
    const priceMax = priceTo ? parseInt(priceTo) : Infinity;
    const yearMin = yearFrom ? parseInt(yearFrom) : 0;
    const yearMax = yearTo ? parseInt(yearTo) : Infinity;

    const filtered = carsData.filter((car) => {
      const inPriceRange = car.price >= priceMin && car.price <= priceMax;
      const inYearRange = car.year >= yearMin && car.year <= yearMax;
      const matchesBrand = selectedBrand === 'Все марки' || car.name === selectedBrand;
      return inPriceRange && inYearRange && matchesBrand;
    });

    setFilteredCars(filtered);
    setCurrentPage(1); // Reset to the first page after filtering
  };

  // Pagination Logic
  const indexOfLastCar = currentPage * carsPerPage;
  const indexOfFirstCar = indexOfLastCar - carsPerPage;
  const currentCars = filteredCars.slice(indexOfFirstCar, indexOfLastCar);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <div>
        <Row style={{ margin: '0' }}>
          <Form
            style={{
              backgroundColor: 'white',
              borderRadius: '1vw',
              marginBottom: '1vw',
              padding: '1vw',
              color: 'black',
              textAlign: 'left'
            }}
          >
            <Row>
              <Col>
                <Form.Group controlId="brandSelect">
                  <Form.Label>Марка</Form.Label>
                  <Form.Select value={selectedBrand} onChange={(e) => setSelectedBrand(e.target.value)}>
                    <option>Все марки</option>
                    <option>BMW</option>
                    <option>Subaru</option>
                    <option>Mercedes</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Row>
                  <Col>
                    <Form.Group controlId="priceFrom">
                      <Form.Label>Цена</Form.Label>
                      <Form.Control
                        type="number"
                        value={priceFrom}
                        onChange={(e) => setPriceFrom(e.target.value)}
                        placeholder="От"
                      />
                    </Form.Group>
                  </Col>
                  <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                    <Form.Group controlId="priceTo">
                      <Form.Control
                        type="number"
                        value={priceTo}
                        onChange={(e) => setPriceTo(e.target.value)}
                        placeholder="До"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col>
                    <Form.Group controlId="yearFrom">
                      <Form.Label>Год</Form.Label>
                      <Form.Control
                        type="number"
                        value={yearFrom}
                        onChange={(e) => setYearFrom(e.target.value)}
                        placeholder="От"
                      />
                    </Form.Group>
                  </Col>
                  <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                    <Form.Group controlId="yearTo">
                      <Form.Control
                        type="number"
                        value={yearTo}
                        onChange={(e) => setYearTo(e.target.value)}
                        placeholder="До"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                <Button
                  onClick={handleApply}
                  className="hover-outline-btn btnn"
                  style={{ width: '50%', height: 'auto' }}
                >
                  Применить
                </Button>
              </Col>
            </Row>
          </Form>
        </Row>
      </div>

      <div className="card-container">
        <Row>
          {currentCars.length > 0 ? (
            currentCars.map((car, index) => (
              <Col lg={4} style={{ marginTop: '.8rem' }} key={index}>
                <Card style={{ width: '100%' }}>
                  <Card.Body className="carcard">
                    <Card.Title className="NameCar">{car.name}</Card.Title>
                    <Card.Text>
                      <Row className="rowgod">
                        <Col className="god">Год</Col>
                        <Col className="godname">{car.year} г.</Col>
                      </Row>
                      <Row className="rowprobeg">
                        <Col className="probeg">Пробег</Col>
                        <Col className="probegname">{car.mileage}</Col>
                      </Row>
                      <Row className="rowcena">
                        <Col className="cena">{car.price.toLocaleString()} руб.</Col>
                      </Row>
                    </Card.Text>
                    <Button className="hover-outline-btn btnn">Забронировать авто</Button>
                  </Card.Body>
                </Card>
              </Col>
            ))
          ) : (
            <p>Нет доступных автомобилей по выбранным параметрам.</p>
          )}
        </Row>
      </div>

      {/* Pagination Component */}
      {filteredCars.length > carsPerPage && (
        <Pagination className="justify-content-center" style={{ marginTop: '1rem' }}>
          {Array.from({ length: Math.ceil(filteredCars.length / carsPerPage) }, (_, index) => (
            <Pagination.Item key={index + 1} active={index + 1 === currentPage} onClick={() => paginate(index + 1)}>
              {index + 1}
            </Pagination.Item>
          ))}
        </Pagination>
      )}
    </div>
  );
};

export default CarList;
